import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/community-detail.css'
import {Row, Col} from 'react-bootstrap'
import {navigate} from 'gatsby'

import Arrow from '../images/left-arrow.png'
import GooglePlay from '../images/google-store.png'
import Love from "../images/icon-com-love.png";
import Comment from "../images/icon-com-msg.png";
import Share from "../images/icon-com-share.png";

class CommunityDetail extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        id: "",
        profle_pict: "",
        from_name: "",
        posted_time: "",
        plant_image: "",
        plant: "",
        part: [],
        msg_pict: [],
        description: "",
        location: "",
        num_of_likes: "",
        num_of_comments: "",
        date: "",
        comments: [],
        }
    }

    getPartName(parts){
        var part_name = []
        for(var part in parts){
            if(part == "0") {
                part_name.push('Daun')
            } else if(part == "1") {
                part_name.push('Batang')
            } else if(part == "2") {
                part_name.push('Buah')
            } else if(part == "3") {
                part_name.push('Akar')
            }
        }
        if(part_name.length == 4){
            return ['Seluruhnya terkena']
        } else{
            return part_name
        }
    }

    getTime(timemilis){
        var dateNow = new Date();
        var dateOld = new Date(timemilis);

        var hours = parseInt(Math.abs(dateNow - dateOld) / 36e5);
        var dateDiff = parseInt((dateNow-dateOld)/(24*3600*1000));

        if(hours < 24){
            return hours + ' jam'
        } else{
            return dateDiff + ' hari'
        }
    }
    
    getDate(timemilis){
        var d = new Date(timemilis);
        var month = new Array();
        month[0] = "Januari";
        month[1] = "Februari";
        month[2] = "Maret";
        month[3] = "April";
        month[4] = "Mei";
        month[5] = "Juni";
        month[6] = "Juli";
        month[7] = "Agustus";
        month[8] = "September";
        month[9] = "Oktober";
        month[10] = "November";
        month[11] = "Desember";

        var date = d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear();
        var time = d.getHours() + ":" + d.getMinutes();
        var dateTime = time + ' - ' + date;
        return dateTime;
    }

    fetchComment(){
        var url = "https://tania-community-service.azurewebsites.net/website/v2/community/" + this.props.location.state.id
        fetch(url, {method: "GET"})
        .then(response => response.json())
        .then(data => {
            var arrTemp = []
            var comments = data['payload']["comments"]
            console.log(comments)
            for(var idx in comments){
                
                var name = ""
                name = comments[idx]["user_name"]

                var answer = ""
                answer = comments[idx]["answer"]
                
                var answered_at = ""
                answered_at = comments[idx]["answered_at"]

                arrTemp.push({"name": name, "answer": answer, "answered_at": answered_at})
            }
            this.setState({comments: arrTemp.reverse()})
        })
    }

    componentDidMount(){
        if (this.props.location.state!=null) {
            this.setState({id: this.props.location.state.id})
            this.setState({profle_pict: this.props.location.state.profle_pict})
            this.setState({from_name: this.props.location.state.from_name})
            this.setState({posted_time: this.props.location.state.posted_time})
            this.setState({plant_image: this.props.location.state.plant_image})
            this.setState({plant: this.props.location.state.plant})
            this.setState({part: this.props.location.state.part})
            this.setState({msg_pict: this.props.location.state.msg_pict})
            this.setState({description: this.props.location.state.description})
            this.setState({location: this.props.location.state.location})
            this.setState({num_of_likes: this.props.location.state.num_of_likes})
            this.setState({num_of_comments: this.props.location.state.num_of_comments})
            this.setState({date: this.props.location.state.date})

            this.fetchComment()

        } else{
            navigate('/community')
        }
    }

    render(){

        return(

            <Layout>
                <Head title = "Community"/>
                <div id = "com-main-container">
                    
                    <div id = "com-title">

                        <div id = "com-title-primary">Community</div>

                        <div id = "com-join-btn">Join Us</div>

                    </div>

                    <div id = "com-body">
                        <div id = "com-det-body-header">
                            <img id = "header-back-btn" src = {Arrow} onClick = {() => navigate('/community')}/>
                            Pertanyaan
                        </div>
                        <div class = "com-item">
                            <div class = "com-item-header">
                                <Row>
                                    <div class = "item-header-profic"></div>
                                    <Col>
                                        <div class = "item-header-container">
                                            <div class = "item-header-name">{this.state.from_name}</div>
                                            <div class = "item-header-divider"> </div>
                                            <div class = "item-header-time">{this.state.posted_time}</div>
                                        </div>
                                        <div class = "item-header-location">{this.state.location}</div>
                                    </Col>
                                </Row>
                            </div>
                            <div class = "com-item-content">
                                <div class = "item-content-category">
                                    {this.state.plant != "" && <div class = "content-category-plant">
                                    <img class = "content-category-logo" src = {this.state.plant_image}/>
                                    {this.state.plant}
                                    </div>}
                                    {this.getPartName(this.state.part).map((part) => (
                                    <div class = "content-category-part">
                                        <div class = "part-logo"></div>
                                        {part}
                                    </div>
                                    ))}
                                </div>
                                <div class = "item-content-msg">
                                    {this.state.description}
                                </div>
                                    
                                {this.state.msg_pict.length != 0 && <img class = "content-pict-main" src = {this.state.msg_pict[0]}/>}
                                    
                                {this.state.msg_pict.length != 0 && <div class = "content-pict-container">

                                    {this.state.msg_pict.filter(function(item){return item != this.state.msg_pict[0]}).map((img) => (
                                        <img class = "content-pict" src = {img}/>
                                    ))}

                                </div>}

                                <div class = "item-date">
                                    {this.state.date}
                                </div>
                                
                            </div>
                        </div>
                        <div class = "com-comment-section">
                            <div class = "comment-section-title">Tanggapan</div>

                            {this.state.comments.length == 0 && <div>Belum ada komentar</div>}
                            
                            {this.state.comments.map((comment) => (
                                <div class = "comment-item">
                                    <div class = "comment-profile-pict"></div>
                                    <div class = "comment-container">
                                        <div class = "comment-content">
                                             <div class = "comment-content-head">
                                                <div class = "comment-name">{comment.name}</div>
                                                <div class = "comment-time">{this.getTime(comment.answered_at)}</div>
                                            </div>
                                            {comment.answer}
                                        </div>
                                        <div class = "comment-date">
                                            {this.getDate(comment.answered_at)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div class = "com-det-footer">
                            Download Aplikasi Dr. Tania untuk berkomentar.
                        </div>
                    </div>

                    <div id = "com-footer">
                        <div id = "com-footer-text">
                            Join our supportive community. Download Dr. Tania app now.
                        </div>
                        <div id = "com-footer-logo">
                            <img id = "com-icon" src = {GooglePlay}/>
                        </div>
                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default CommunityDetail;